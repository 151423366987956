<template>
  <v-autocomplete
    v-model="value"
    :items="companyList"
    :loading="companyList.length === 0"
    label="Mã chứng khoán"
    dense
    outlined
    hide-details
    hide-selected
  />
</template>

<script>

import { mapGetters } from 'vuex'

export default {

  data() {
    return {
      value: '',
    }
  },

  computed: {
    ...mapGetters('company', ['companyList']),
  },

  watch: {
    value(symbol) {
      this.$emit('symbol-changed', symbol)
    },
    companyList() {
      this.selectItem()
    },
  },

  created() {
    this.selectItem()
  },

  methods: {
    selectItem() {
      const { symbol } = this.$route.query
      if (this.companyList.length > 0) {
        if (symbol) {
          this.value = symbol.toUpperCase()
        } else {
          this.value = this.companyList[0].value
        }
      }
    },
  },
}

</script>
